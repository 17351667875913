<template>
    <div>
        <van-empty v-if="state == 1" :description="description" />
        <van-empty v-else-if="state == 0" image="network" :description="description" />
        <van-empty v-else image="error" :description="description" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            description: '绑定中',
            state: 0,  // 0 请求中  1 成功 2 失败
        }
    },
    components: {
    },
    mounted() {
        this.$request(6, { openid: this.$localStorage.get('openid') }, '绑定中')
            .then((res) => {
                let { code, msg } = res
                this.state = code;
                this.description = msg;
            })
            .catch((message) => {
                this.state = 2;
                this.description = "绑定失败";
            })
    },
    methods: {

    },
}

</script>
<style scoped lang='less'></style>